import React from "react";
import logo from "../../assets/favicon.png";

export const LoadingPage = () => {
	return (
		<div className="loading-div">
			<img className="loading-div__image" src={logo} alt="Loading page..." />
		</div>
	);
};
