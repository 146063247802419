import axios from "axios";

const serverUrl = `http://smart-meeting.herokuapp.com`;
const headers = {
	token: `1c481c27500fca5d3c21eb64afaeda5c476c4f75dd256f7f81e00ec980bb3510`,
};

export function getAllData() {
	const query = {
		operationName: null,
		query: `{
            Buildings {
          name
          id
          meetingRooms {
            id
            name
            floor
                meetings {
            id
              title
              date
              startTime
              endTime
        }
    }
        },
    }`,
		variables: {},
	};

	return axios.post(serverUrl, query, { headers });
}

export function createMeetingApi(payload: string) {
	const mutation = {
		operationName: null,
		query: `mutation {
            Meeting${payload} {
                id
                title
                date
                startTime
                endTime
            }
        }`,
		variables: {},
	};
	return axios.post(serverUrl, mutation, { headers });
}

export function createMeetingRoomApi(payload: string) {
	const mutation = {
		operationName: null,
		query: `mutation {
            MeetingRoom${payload} {
                id
                name
            }
        }`,
		variables: {},
	};
	return axios.post(serverUrl, mutation, { headers });
}

export function createBuildingApi(payload: string) {
	const mutation = {
		operationName: null,
		query: `mutation {
            Building${payload} {
                id
                name
            }
        }`,
		variables: {},
	};
	return axios.post(serverUrl, mutation, { headers });
}
