import { IMeetingRoomResponse } from "../models/general";
import { isMeetingOngoing } from "./TimeUtils";

export const checkRoomAvailable = (rooms: IMeetingRoomResponse[]) => {
    let availableRooms: IMeetingRoomResponse[] = [];
    rooms?.forEach(room => {
        let available = true;
        for (let meeting of room.meetings) {
            if (isMeetingOngoing(meeting)) {
                available = false;
                break;
            }
        }
        if(available) availableRooms.push(room);
    });
    return availableRooms;
}

