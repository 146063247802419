import { ChartDonut, ChartThemeColor } from "@patternfly/react-charts";
import { useEffect, useState } from "react";

interface IDonutChart {
	name: string;
	title: string;
	total: number;
	data: any[];
}

export const DonutChart = ({ total, data, title, name }: IDonutChart) => {
	const legendData = data.map((datum) => {
		return {
			name: `${datum.x}: ${datum.y}`,
		};
	});

	const [isMobile, setMobile] = useState(window.innerWidth < 765);

	useEffect(() => {
		window.onresize = () => {
			if (window.innerWidth < 765) {
				setMobile(true);
			} else setMobile(false);
		};
	}, []);

	return (
		<div style={{ height: "150px", width: "350px" }}>
			<ChartDonut
				ariaDesc={title}
				ariaTitle={`${title} chart`}
				constrainToVisibleArea
				data={data}
				height={150}
				labels={({ datum }) => `${datum.x}: ${datum.y}`}
				legendData={legendData}
				legendOrientation="vertical"
				legendPosition="right"
				name={title}
				padding={{
					bottom: 20,
					left: 20,
					right: 145, // Adjusted to accommodate legend
					top: 20,
				}}
				subTitle={total > 1 ? `${name}s` : name}
				title={`${total}`}
				width={275}
				themeColor={ChartThemeColor.green}
			/>
		</div>
	);
};
