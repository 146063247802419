import { Spinner, Wizard } from "@patternfly/react-core";
import { useEffect } from "react";
import { IBuildingResponse, IMeetingResponse } from "../../../models/general";
import {
	CreateMeetingReducerConstants,
	useCreateMeetingContext,
} from "./CreateMeetingState";
import SelectRoomStep from "./Steps/SelectRoomStep";
import TimeSelectStep from "./Steps/TimeSelectStep";
import "./create-meeting-wizard.scss";
import ReviewStep from "./Steps/ReviewStep";
import { timeComparator } from "../../../utils/TimeUtils";
import { createMeetingApi } from "../../../api/smart-meeting";
import { useFetch } from "../../../hooks/useFetch";
import { toast } from "react-toastify";

interface ICreateMeetingWizard {
	isOpen: boolean;
	handleModalToggle: () => void;
	buildingList: IBuildingResponse[];
	meetings: IMeetingResponse[];
	updateMeetings: () => void;
}

export const CreateMeetingWizard = (props: ICreateMeetingWizard) => {
	const {
		dispatch,
		createMeetingState: {
			selectedBuilding,
			date,
			startTime,
			endTime,
			title,
			selectedRoom,
		},
	} = useCreateMeetingContext();
	const { callApi, isFetching } = useFetch(createMeetingApi);

	const generateMeetingId = () => {
		let meetingIds = props.meetings?.map((meeting) => meeting.id) || [];
		return Math.max(...meetingIds) + 1;
	};

	const createMeeting = async () => {
		const payload = `(
            id: ${generateMeetingId()}
            title: "${title}"
            date: "${date}"
            startTime: "${startTime}"
            endTime: "${endTime}"
            meetingRoomId: ${selectedRoom?.id}
        )`;
		try {
			await callApi(payload);
			handleModalClose();
			props.updateMeetings();
			toast.success("Yayy! Meeting created successfully!!", {
				theme: "colored",
			});
		} catch (error) {}
	};

	useEffect(() => {
		dispatch({
			type: CreateMeetingReducerConstants.buildingList,
			payload: props.buildingList,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.buildingList]);

	//checking if the step1 fields are populated
	const checkStepEnabled = (): boolean => {
		return !!(selectedBuilding && title && date && startTime && endTime);
	};

	const steps = [
		{
			id: "1",
			name: "Time and Buliding",
			component: <TimeSelectStep />,
			canJumpTo: true,
			enableNext: checkStepEnabled() && !timeComparator(startTime, endTime),
		},
		{
			id: "2",
			name: "Select a Room",
			component: <SelectRoomStep />,
			canJumpTo: checkStepEnabled() && !timeComparator(startTime, endTime),
			enableNext: checkStepEnabled() && !!selectedRoom,
		},
		{
			id: "3",
			name: "Review",
			component: <ReviewStep />,
			canJumpTo:
				checkStepEnabled() &&
				!!selectedRoom &&
				!timeComparator(startTime, endTime),
			nextButtonText: (
				<>
					{isFetching && <Spinner size="md" />}
					&nbsp; Create a Meeting
				</>
			),
			enableNext: !isFetching,
			hideCancelButton: isFetching,
			hideBackButton: isFetching,
		},
	];
	const handleModalClose = () => {
		//before closing modal, clearing the modal/wizard state
		dispatch({ type: CreateMeetingReducerConstants.clearState, payload: {} });
		props.handleModalToggle();
	};

	const handleSave = () => {
		createMeeting();
	};

	return (
		<Wizard
			title={"Add a Meeting"}
			onClose={handleModalClose}
			isOpen={props.isOpen}
			width={"clamp(20rem, 70vw, 60rem)"}
			navAriaLabel={`${title} steps`}
			mainAriaLabel={`${title} content`}
			steps={steps}
			height={400}
			description="Hola! I am your meeting setup wizard!"
			onSave={handleSave}
		/>
	);
};
