import { IMeetingResponse } from "../models/general";

export const minsElapsedForDay = (hour: number, min: number) => hour * 60 + min;

export const isMeetingToday = (
	meetingDate: string,
	customDate = new Date()
) => {
	const currDate = customDate;
	const [day, month, year] = meetingDate?.split("/");
	return (
		Number(day) === currDate.getDate() &&
		Number(month) === currDate.getMonth() + 1 &&
		Number(year) === currDate.getFullYear()
	);
};

export const isItMeetingTime = (startTime: string, endTime: string) => {
	const currDate = new Date();
	const [startHour, startMinute, endHour, endMinute] = [
		...startTime.split(":"),
		...endTime.split(":"),
	].map((item) => Number(item));
	const [currentHour, currentMin] = [
		currDate.getHours(),
		currDate.getMinutes(),
	];
	const minsElapsedToday = minsElapsedForDay(currentHour, currentMin);
	if (
		minsElapsedToday >= minsElapsedForDay(startHour, startMinute) &&
		minsElapsedToday <= minsElapsedForDay(endHour, endMinute)
	) {
		return true;
	}
	return false;
};

export const getMins4mStr = (timeString: string) => {
	const [hours, mins] = timeString.split(":").map((item) => Number(item));
	return minsElapsedForDay(hours, mins);
};

export const checkMeetingTimeOverLap = (
	firstStartTime: string,
	firstEndTime: string,
	secondStartTime: string,
	secondEndTIme: string
) => {
	const [firstStartMins, firstEndMins, secondStartMins, secondEndMins] = [
		firstStartTime, //10:49
		firstEndTime,
		secondStartTime,
		secondEndTIme,
	].map((item) => getMins4mStr(item));

	return (
		(firstStartMins >= secondStartMins && firstStartMins < secondEndMins) ||
		(firstEndMins > secondStartMins && firstEndMins <= secondEndMins) ||
		(firstEndMins < secondStartMins && firstEndMins > secondEndMins)

	);
};

export const isMeetingOngoing = (
	meeting: IMeetingResponse,
	customDate?: Date,
	customStartTime?: string,
	customEndTIme?: string
) => {
	return (
		isMeetingToday(meeting.date, customDate) &&
		isItMeetingTime(meeting.startTime, meeting.endTime)
	);
};

export const hasMeetingFinished = (endTime: string) => {
	const currDate = new Date();
	const [currentHour, currentMin] = [
		currDate.getHours(),
		currDate.getMinutes(),
	];
	return minsElapsedForDay(currentHour, currentMin) > getMins4mStr(endTime);
};

export const isMeetingUpcoming = (startTime: string) => {
	const currDate = new Date();
	const [currentHour, currentMin] = [
		currDate.getHours(),
		currDate.getMinutes(),
	];
	return minsElapsedForDay(currentHour, currentMin) < getMins4mStr(startTime);
};


export const dateValidator = (date: Date) => {
	const currDate = new Date();
	const todayDate = new Date(
		currDate.getFullYear(),
		currDate.getMonth(),
		currDate.getDate()
	);
	if (date < todayDate) {
		return "Cannot set past date for meeting";
	}
	return "";
};

export const convertTimeStringTo12hrs = (timeString: string) => {
	if (!timeString || timeString.length !== 5) return "";
	const [hour, minute] = timeString.split(":").map((item) => Number(item));
	let hourNum = hour > 12 ? hour - 12 : hour;
	if (hourNum === 0) hourNum = 12;
	let timeString12Hrs = `${padTimeString(hourNum, minute)} ${
		hour > 12 ? "PM" : "AM"
	}`;
	return timeString12Hrs;
};

export const padTimeString = (hour: number, minute: number): string => {
	let timeString = hour >= 0 ? (hour < 10 ? `0${hour}` : `${hour}`) : "";
	timeString = timeString +=
		minute >= 0 ? (minute < 10 ? `:0${minute}` : `:${minute}`) : "";
	return timeString;
};

//the below two are method props to DatePicker to show date in Indian favourite format DD/MM/YYYY
export const dateFormat = (date: Date) =>
	date
		.toLocaleDateString("en-IN", {
			year: "numeric",
			month: "2-digit",
			day: "2-digit",
		})
		.replace(/\//g, "/");

export const dateParse = (date: string) => {
	const split = date.split("/");
	if (split.length !== 3) {
		return new Date();
	}
	const month = split[1];
	const day = split[0];
	const year = split[2];
	return new Date(
		`${year.padStart(4, "0")}-${month.padStart(2, "0")}-${day.padStart(
			2,
			"0"
		)}T00:00:00`
	);
};

export const timeComparator = (startTime: string, endTime: string) => {
	if (!startTime || !endTime) return false;
	let [endHours, endMins] = endTime.split(":").map((item) => Number(item));
	let [startHour, startMins] = startTime.split(":").map((item) => Number(item));

	return (
		minsElapsedForDay(endHours, endMins) <
		minsElapsedForDay(startHour, startMins)
	);
};
