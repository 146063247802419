import {
	DescriptionList,
	DescriptionListDescription,
	DescriptionListGroup,
	DescriptionListTerm,
	Divider,
	Title,
} from "@patternfly/react-core";
import { BuildingIcon, CalendarIcon, ClockIcon } from "@patternfly/react-icons";
import React from "react";
import { convertTimeStringTo12hrs } from "../../../../utils/TimeUtils";
import { useCreateMeetingContext } from "../CreateMeetingState";
import roomIcon from "../../../../assets/conference-room-1.svg";

const ReviewStep: React.FC<{}> = () => {
	const { createMeetingState } = useCreateMeetingContext();
	const data2Render = {
		Title: createMeetingState.title,
		Date: (
			<>
				{createMeetingState.date}&ensp;
				<CalendarIcon color="blue" />
				&ensp;
			</>
		),
		"Start Time": (
			<>
				{convertTimeStringTo12hrs(createMeetingState.startTime)}&ensp;
				<ClockIcon color="green" />
			</>
		),
		"End Time": (
			<>
				{convertTimeStringTo12hrs(createMeetingState.endTime)}&ensp;
				<ClockIcon color="red" />
			</>
		),
		Building: (
			<>
				{createMeetingState.selectedBuilding}&ensp;
				<BuildingIcon color="grey"/>
			</>
		),
		Room: (
			<>
				{createMeetingState.selectedRoom?.name}&ensp;
				<img
					style={{ height: "30px", transform: "translateY(6px)" }}
					src={roomIcon}
					alt="meeting-room-icon"
				/>
			</>
		),
	};

	return (
		<>
			<Title headingLevel="h2">Does this look good?</Title>
			<Divider />
			<br />
			<DescriptionList isHorizontal>
				{Object.entries(data2Render).map((item) => (
					<DescriptionListGroup>
						<DescriptionListTerm>{item[0]}</DescriptionListTerm>
						<DescriptionListDescription>{item[1]}</DescriptionListDescription>
					</DescriptionListGroup>
				))}
			</DescriptionList>
		</>
	);
};

export default ReviewStep;
