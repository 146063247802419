import {
	DatePicker,
	Form,
	FormGroup,
	Select,
	SelectOption,
	SelectOptionObject,
	SelectVariant,
	TextInput,
	TimePicker,
	Title,
	ValidatedOptions,
} from "@patternfly/react-core";
import { BuildingIcon } from "@patternfly/react-icons";
import { useState } from "react";
import {
	convertTimeStringTo12hrs,
	dateFormat,
	dateParse,
	dateValidator,
	padTimeString,
	timeComparator,
} from "../../../../utils/TimeUtils";
import {
	CreateMeetingReducerConstants,
	useCreateMeetingContext,
} from "../CreateMeetingState";

const TimeSelectStep = () => {
	const {
		createMeetingState: {
			buildingList,
			date,
			title,
			startTime,
			endTime,
			selectedBuilding,
		},
		dispatch,
	} = useCreateMeetingContext();
	const [isOpen, setOpen] = useState(false);
	const onToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const onSelect = (
		_event: React.MouseEvent<Element, MouseEvent> | React.ChangeEvent<Element>,
		value: string | SelectOptionObject
	) => {
		dispatch({
			type: CreateMeetingReducerConstants.selectedBuilding,
			payload: value as string,
		});
		setOpen(false);
	};
	const handleFormUpdates = (
		value: string,
		actionType: CreateMeetingReducerConstants
	) => {
		dispatch({ type: actionType, payload: value });
	};

	const handleTimeUpdates = (
		hour = -1,
		minute = -1,
		actionType: CreateMeetingReducerConstants
	) => {
		let timeString = padTimeString(hour, minute);
		if (timeString.length !== 5) return;
		handleFormUpdates(timeString, actionType);
	};

	return (
		<Form isHorizontal onSubmit={() => {}}>
			<Title headingLevel="h2">
				Would you tell me a bit about your meeting?
			</Title>
			<FormGroup label="Title" isRequired>
				<TextInput
					value={title}
					onChange={(value) =>
						handleFormUpdates(value, CreateMeetingReducerConstants.title)
					}
					required
					placeholder="Need a title for your meeting"
				/>
			</FormGroup>
			<FormGroup label="Date" isRequired>
				<DatePicker
					validators={[dateValidator]}
					value={date}
					placeholder={"DD/MM/YYYY"}
					dateFormat={dateFormat}
					dateParse={dateParse}
					invalidFormatText={"Whoops! seems like some problem with date 🥲"}
					onChange={(value, date) =>
						handleFormUpdates(value, CreateMeetingReducerConstants.date)
					}
				/>
			</FormGroup>
			<FormGroup label="Start Time" isRequired>
				<TimePicker
					time={convertTimeStringTo12hrs(startTime)}
					stepMinutes={15}
					onChange={(_time, hour, minute) =>
						handleTimeUpdates(
							hour,
							minute,
							CreateMeetingReducerConstants.startTime
						)
					}
				/>
			</FormGroup>
			<FormGroup
				label="End Time"
				isRequired
				validated={
					!timeComparator(startTime, endTime)
						? ValidatedOptions.default
						: ValidatedOptions.error
				}
				helperTextInvalid="Oops! your meeting will end before it starts 😅"
			>
				<TimePicker
					time={convertTimeStringTo12hrs(endTime)}
					stepMinutes={15}
					onChange={(_time, hour, minute) =>
						handleTimeUpdates(
							hour,
							minute,
							CreateMeetingReducerConstants.endTime
						)
					}
				/>
			</FormGroup>

			<FormGroup label="Building" isRequired>
				<Select
					toggleIcon={<BuildingIcon />}
					variant={SelectVariant.single}
					aria-label="Select a Building"
					placeholderText="Which building would you like to go?"
					onToggle={onToggle}
					onSelect={onSelect}
					selections={selectedBuilding}
					isOpen={isOpen}
					aria-labelledby={"building selector"}
				>
					{buildingList.map((option, index) => (
						<SelectOption key={index} value={option.name} />
					))}
				</Select>
			</FormGroup>
		</Form>
	);
};

export default TimeSelectStep;
