import React, { useContext, useReducer } from "react";
import { IBuildingResponse, IMeetingRoomResponse } from "../../../models/general";

//reducer stuff
export interface ICreateMeetingState {
    buildingList: IBuildingResponse[];
    title: string;
    date: string;
    startTime: string;
    endTime: string;
    selectedBuilding: string;
    selectedRoom: Partial<IMeetingRoomResponse> | null;
}

const initialCreateMeetState: ICreateMeetingState = {
    buildingList: [],
    title: '',
    date: '',
    startTime: '',
    endTime: '',
    selectedBuilding: '',
    selectedRoom: null,
}

export enum CreateMeetingReducerConstants {
    buildingList = "buildingList",
    title='title',
    date = 'date',
    startTime = 'startTime',
    endTime = 'endTime',
    selectedBuilding = 'selectedBuilding',
    selectedRoom = 'selectedRoom',
    clearState = "clearState"
}

interface IAction {
    type: CreateMeetingReducerConstants,
    payload: any
}

const CreateMeetingReducer = (state: ICreateMeetingState, action: IAction) => {
    switch (action.type) {
        case CreateMeetingReducerConstants.clearState: return initialCreateMeetState;
        default:
            return { ...state, [action.type]: action.payload }
    }
}

//context stuff
interface ICreateMeetingContext {
    createMeetingState: ICreateMeetingState;
    dispatch: React.Dispatch<IAction>;
}

const initialCreateMeeting: ICreateMeetingContext = {
    createMeetingState: initialCreateMeetState,
    dispatch: () => { }
}
export const CreateMeetingContext = React.createContext<ICreateMeetingContext>(initialCreateMeeting);
export const useCreateMeetingContext = () => useContext(CreateMeetingContext);

const CreateMeetingProvider = (props: any) => {
    const [createMeetingState, dispatch] = useReducer(CreateMeetingReducer, initialCreateMeetState);
    return <CreateMeetingContext.Provider value={{ createMeetingState, dispatch }}>
        {props.children}
    </CreateMeetingContext.Provider>;
}

export default CreateMeetingProvider;