/* eslint-disable react-hooks/exhaustive-deps */
import {
	Button,
	Grid,
	GridItem,
	Masthead,
	MastheadBrand,
	MastheadContent,
	MastheadMain,
	Page,
	PageSection,
	PageSectionVariants,
	Title,
	ToolbarItem,
} from "@patternfly/react-core";
import { UserIcon } from "@patternfly/react-icons";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getAllData } from "../../api/smart-meeting";
import logo from "../../assets/quickbooks.png";
import { useFetch } from "../../hooks/useFetch";
import {
	IBuildingResponse,
	IMeetingResponse,
	IMeetingRoomResponse,
} from "../../models/general";
import { isMeetingToday } from "../../utils/TimeUtils";
import Building from "../Buildings/Buliding";
import CreateMeetingProvider from "../Meetings/CreateMeetingWizard.tsx/CreateMeetingState";
import { CreateMeetingWizard } from "../Meetings/CreateMeetingWizard.tsx/CreateMeetingWizard";
import Meetings from "../Meetings/Meeting";
import Rooms from "../Rooms/Rooms";
import "./homepage.scss";
import { LoadingPage } from "./LoadingPage";

interface IHomepage {}

const Homepage: React.FC = (props: IHomepage) => {
	const [isModalOpen, setModalOpen] = useState(false);
	const [buildings, setBuildings] = useState<IBuildingResponse[]>([]);
	const [meetingRooms, setMeetingRooms] = useState<IMeetingRoomResponse[]>([]);
	const [meetings, setMeetings] = useState<IMeetingResponse[]>([]);

	const { callApi, isFetching } = useFetch(getAllData);
	const [isSilentUpdate, setSilentUpdate] = useState(false);

	const handleModalToggle = () => {
		setModalOpen((preOpen) => !preOpen);
	};

	const processResponse = (data: any) => {
		setBuildings(data?.Buildings);
		const tempMeetingRooms: IMeetingRoomResponse[] = [];
		data.Buildings?.forEach((building: IBuildingResponse) =>
			tempMeetingRooms.push(...(building?.meetingRooms || []))
		);
		setMeetingRooms(tempMeetingRooms);
		const tempMeetings: IMeetingResponse[] = [];
		tempMeetingRooms.forEach((room: IMeetingRoomResponse) =>
			tempMeetings.push(...room.meetings)
		);
		setMeetings(tempMeetings);
	};

	const fetchAllData = async (isSilent?: boolean) => {
		isSilent && setSilentUpdate(true);
		try {
			const response = await callApi();
			processResponse(response?.data?.data);
		} catch (error: any) {
			toast.error(error.toString(), {
				theme: "colored",
				autoClose: false,
			});
		} finally {
			setSilentUpdate(false);
		}
	};

	useEffect(() => {
		fetchAllData();
	}, []);

	const header = (
		<Masthead className="smo-header">
			<MastheadMain>
				<MastheadBrand className="smo-header__brand" target="_blank">
					<img
						className="smo-header__brand__image"
						src={logo}
						alt="intuit-logo"
					/>
				</MastheadBrand>
			</MastheadMain>
			<MastheadContent>
				<ToolbarItem variant="separator" />
				<Title className="smo-header__heading" headingLevel="h1">
					Smart Meeting Organizer
				</Title>
				<span className="smo-header__usericon">
					{" "}
					<UserIcon size="lg" />
				</span>
			</MastheadContent>
		</Masthead>
	);

	return (
		<Page className="smo-page" header={header}>
			<PageSection
				className="smo-page__section"
				variant={PageSectionVariants.light}
			>
				{isFetching && !isSilentUpdate ? (
					<LoadingPage />
				) : (
					<Grid>
						<GridItem md={12} lg={6}>
							<Building buildings={buildings} refreshApi={fetchAllData} />
						</GridItem>
						<GridItem md={12} lg={6}>
							<Rooms rooms={meetingRooms} refreshApi={fetchAllData} />
						</GridItem>
						<GridItem>
							<Meetings
								meetings={meetings.filter((meeting) =>
									isMeetingToday(meeting.date)
								)}
							/>
						</GridItem>
						<GridItem>
							{" "}
							<Button
								onClick={() => setModalOpen(true)}
								className="smo-page__section__add-btn"
							>
								Add new meeting
							</Button>{" "}
						</GridItem>
					</Grid>
				)}
			</PageSection>
			<CreateMeetingProvider>
				{isModalOpen && (
					<CreateMeetingWizard
						isOpen={isModalOpen}
						handleModalToggle={handleModalToggle}
						buildingList={buildings}
						meetings={meetings}
						updateMeetings={() => fetchAllData(true)}
					/>
				)}
			</CreateMeetingProvider>{" "}
		</Page>
	);
};
export default Homepage;
