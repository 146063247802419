import {
	Card,
	CardBody,
	CardTitle,
	Divider,
	Split,
	SplitItem,
} from "@patternfly/react-core";
import React, { ReactElement, useEffect, useState } from "react";
import { createMeetingRoomApi } from "../../api/smart-meeting";
import { IMeetingRoomResponse } from "../../models/general";
import { checkRoomAvailable } from "../../utils/MiscUtils";
import { DonutChart } from "../Charts/DonutChart";
import { CreateModalForm } from "../General/CreateModal";
import "./rooms.scss";

interface IRooms {
	rooms: IMeetingRoomResponse[];
	refreshApi: (value?: boolean) => void;
}

const roomModalForm = {
	id: {
		display: "Id",
		type: "number",
	},
	name: {
		display: "Room's Name",
		type: "string",
	},
	floor: {
		display: "Floor Number",
		type: "number",
	},
	buildingId: {
		display: "Building ID",
		type: "number",
	},
};
const Rooms: React.FC<IRooms> = (props: IRooms): ReactElement => {
	const [availableRooms, setAvailableRooms] =
		useState<IMeetingRoomResponse[]>();
	const reEvaluateAvailability = () => {
		let tempRooms = checkRoomAvailable(props.rooms);
		setAvailableRooms(tempRooms);
	};

	useEffect(reEvaluateAvailability, [props.rooms]);

	return (
		<>
			<Card isRounded className="rooms-card" isCompact isSelectable>
				<CardTitle>
					<Split>
						<SplitItem isFilled>Rooms</SplitItem>
						<SplitItem>
							<CreateModalForm
								name="Room"
								createFormStructure={roomModalForm}
								createdApi={createMeetingRoomApi}
								refreshApi={props.refreshApi}
							/>
						</SplitItem>
					</Split>
				</CardTitle>
				<Divider />
				<CardBody className="rooms-card__body">
					{/* 				<div>
					Total {props.rooms.length || 0}
					<br />
					Free now {availableRooms?.length || 0}
				</div> */}
					<DonutChart
						total={props.rooms?.length || 0}
						title="Rooms Availability"
						name="Room"
						data={[
							{ x: "Available", y: availableRooms?.length || 0 },
							{
								x: "Occupied",
								y: props.rooms?.length - (availableRooms?.length || 0),
							},
						]}
					/>
				</CardBody>
			</Card>
		</>
	);
};

export default Rooms;
