import {
	Bullseye,
	SimpleList,
	SimpleListItem,
	Split,
	SplitItem,
	Text,
	Title,
} from "@patternfly/react-core";
import { useEffect, useState } from "react";
import { IMeetingRoomResponse } from "../../../../models/general";
import {
	checkMeetingTimeOverLap,
	dateParse,
	isMeetingToday,
} from "../../../../utils/TimeUtils";
import {
	CreateMeetingReducerConstants,
	useCreateMeetingContext,
} from "../CreateMeetingState";
import meetIcon from "../../../../assets/conference-room-1.svg";
import notFoundImage from "../../../../assets/not_found.svg";

const SelectRoomStep = () => {
	const [availableMeetingRooms, setAvailableMeetingRooms] = useState<
		IMeetingRoomResponse[]
	>([]);

	const {
		createMeetingState: {
			buildingList,
			selectedBuilding,
			selectedRoom,
			date,
			startTime,
			endTime,
		},
		dispatch,
	} = useCreateMeetingContext();
	useEffect(() => {
		const totalRooms =
			buildingList?.find((building) => building.name === selectedBuilding)
				?.meetingRooms || [];
		let availableRooms: IMeetingRoomResponse[] = [];
		totalRooms?.forEach((room) => {
			let available = true;
			for (let meeting of room.meetings) {
				if (
					isMeetingToday(meeting.date, dateParse(date)) &&
					checkMeetingTimeOverLap(
						startTime,
						endTime,
						meeting.startTime,
						meeting.endTime
					)
				) {
					available = false;
					break;
				}
			}
			if (available) availableRooms.push(room);
		});
		setAvailableMeetingRooms(availableRooms);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedBuilding]);

	const items = availableMeetingRooms.map((room, index) => (
		<SimpleListItem
			isActive={selectedRoom?.name === room.name}
			componentProps={{ value: room }}
			component="button"
			className={`meeting-room-list__list-item ${
				selectedRoom?.name === room.name ? "selected" : ""
			}`}
			key={`${room.id}-${index}`}
		>
			<Split>
				<SplitItem isFilled>
					{" "}
					<Title headingLevel="h3">{room.name}</Title>
					{selectedBuilding}
					<br />
					Floor: {room.floor}
				</SplitItem>

				<SplitItem>
					{" "}
					<img
						src={meetIcon}
						style={{ height: "60px" }}
						alt="meet-room icon"
					/>{" "}
				</SplitItem>
			</Split>
		</SimpleListItem>
	));

	const onSelect = (_ref: any, props: any) => {
		dispatch({
			type: CreateMeetingReducerConstants.selectedRoom,
			payload: props.componentProps?.value,
		});
	};

	if (!selectedBuilding || availableMeetingRooms.length === 0) {
		return (
			<Bullseye style={{ paddingTop: "5rem" }}>
				<img
					src={notFoundImage}
					alt="room not found"
					style={{ height: "300px" }}
				/>
				<div>
					<Title headingLevel="h3">No free rooms found!</Title>
					<Text component="p">You might want to try another building.</Text>
				</div>
			</Bullseye>
		);
	}
	return (
		<>
			<Title headingLevel="h2">Any favourite room?</Title>
			<br />
			<SimpleList
				className="meeting-room-list"
				onSelect={onSelect}
				aria-label="Simple List Example"
			>
				{items}
			</SimpleList>
		</>
	);
};

export default SelectRoomStep;
