import {
	Card,
	CardBody,
	CardTitle,
	Divider,
	Split,
	SplitItem,
} from "@patternfly/react-core";
import React, { ReactElement } from "react";
import { IBuildingResponse } from "../../models/general";
import { DonutChart } from "../Charts/DonutChart";
import { CreateModalForm } from "../General/CreateModal";
import "./buliding.scss";

interface IBuilding {
	buildings: IBuildingResponse[];
	refreshApi?: (isSilent: boolean) => void;
}
const createBuldingStructure = {
	id: {
		display: "Id",
		type: "number",
	},
	name: {
		display: "Building Name",
		type: "string",
	},
};

const Building: React.FC<IBuilding> = (props: IBuilding): ReactElement => {
	return (
		<>
			<Card isRounded className="building-card" isCompact isSelectable>
				<CardTitle>
					{" "}
					<Split>
						<SplitItem isFilled>Buildings</SplitItem>
						<SplitItem>
							<CreateModalForm
								createFormStructure={createBuldingStructure}
								name="Building"
								refreshApi={props.refreshApi}
							/>
						</SplitItem>
					</Split>
				</CardTitle>
				<Divider />
				<CardBody className="building-card__body">
					{/* 	<div> Total {props.buildings?.length}</div> */}
					<DonutChart
						total={props.buildings.length}
						title="Building Count"
						name="Building"
						data={[{ x: "Buildings", y: props.buildings.length }]}
					/>
				</CardBody>
			</Card>
			{/* 			<Modal
				variant={ModalVariant.small}
				title="Add a building"
				isOpen={isOpen}
				onClose={handleModalToggle}
				actions={[
					<Button key="confirm" variant="primary" onClick={handleModalToggle}>
						Okayyy!!!
					</Button>,
				]}
			>
				Coming Soon!
				<br />
				Stay Tuned 😃
			</Modal> */}
		</>
	);
};

export default Building;
