import React from "react";
import { ToastContainer } from "react-toastify";
import Homepage from "./components/Homepage/Homepage";
import "react-toastify/dist/ReactToastify.min.css";

function App() {
	return (
		<div className="App">
			<Homepage />
			<ToastContainer autoClose={5000} />
		</div>
	);
}

export default App;
