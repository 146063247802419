import { Card, CardBody, CardTitle, Divider } from "@patternfly/react-core";
import React, { ReactElement, useEffect, useState } from "react";
import { IMeetingResponse } from "../../models/general";
import {
	hasMeetingFinished,
	isItMeetingTime,
	isMeetingUpcoming,
} from "../../utils/TimeUtils";
import { DonutChart } from "../Charts/DonutChart";
import "./meetings.scss";

interface IMeetings {
	meetings: IMeetingResponse[];
}
const Meetings: React.FC<IMeetings> = (props: IMeetings): ReactElement => {
	const [onGoingMeetings, setOnGoingMeetings] = useState<number>();
	const [upComingMeetings, setUpComingMeetings] = useState<number>();
	const [meetingsFinished, setMeetingsFinished] = useState<number>();

	const reEvaluateOngoingMeetings = () => {
		setOnGoingMeetings(
			props.meetings.filter((meet) =>
				isItMeetingTime(meet.startTime, meet.endTime)
			).length
		);
		setMeetingsFinished(
			props.meetings.filter((meet) => hasMeetingFinished(meet.endTime)).length
		);
		setUpComingMeetings(
			props.meetings.filter((meet) => isMeetingUpcoming(meet.startTime)).length
		);
	};
	useEffect(reEvaluateOngoingMeetings, [props.meetings]);
	return (
		<Card isRounded className="meeting-card" isCompact isSelectable>
			<CardTitle>Meetings Today</CardTitle>
			<Divider />
			<CardBody className="meeting-card__body">
				{/* 				<div>
					Total {props.meetings.length} today
					<br />
					Total {onGoingMeetings} going on now
				</div> */}
				<DonutChart
					title="Today's Meetings Chart"
					name="Meeting"
					total={props.meetings?.length || 0}
					data={[
						{ x: "Ongoing Meetings", y: onGoingMeetings },
						{ x: "Upcoming Meetings", y: upComingMeetings },
						{ x: "Meetings Finished", y: meetingsFinished },
					]}
				/>
			</CardBody>
		</Card>
	);
};

export default Meetings;
