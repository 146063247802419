import {
	Button,
	Form,
	FormGroup,
	Modal,
	ModalVariant,
	TextInput,
} from "@patternfly/react-core";
import { PlusIcon } from "@patternfly/react-icons";
import React, { useState } from "react";
import { toast } from "react-toastify";
import {
	createBuildingApi,
	createMeetingRoomApi,
} from "../../api/smart-meeting";
import { useFetch } from "../../hooks/useFetch";

export const CreateModalForm = (props: any) => {
	const { name, createFormStructure } = props;
	const [isOpen, setOpen] = useState(false);
	const [formData, setFormData] = useState<any>({});
	const handleModalToggle = () => {
		setOpen((preOpen) => !preOpen);
	};

	//
	const { callApi, isFetching } = useFetch(
		name === "Building" ? createBuildingApi : createMeetingRoomApi
	);
	const handleSave = async () => {
		const payload = `(
            ${Object.keys(createFormStructure)
							.map(
								(item) =>
									`${item}: ${
										createFormStructure[item]?.type === "string"
											? `"${formData[item]}"`
											: formData[item]
									}`
							)
							.join(",")}
        )`;

		try {
			await callApi(payload);
			handleModalToggle();
			toast.success(`${props.name} Created successfully!`, {
				theme: "colored",
			});
			props.refreshApi(true);
		} catch (error: any) {
			console.error(error);
			toast.error(`some error occured: ${error?.toString()}`, {
				theme: "colored",
				autoClose: false,
			});
		}
	};

	const handleChange = (value: string, name: string) => {
		setFormData((preFormData: any) => {
			return { ...preFormData, [name]: value };
		});
	};
	return (
		<>
			{!isOpen && (
				<Button
					variant="link"
					icon={<PlusIcon />}
					isInline
					onClick={handleModalToggle}
				>
					Add {props.name}
				</Button>
			)}
			<Modal
				variant={ModalVariant.small}
				title={`Add a ${props.name}`}
				isOpen={isOpen}
				onClose={handleModalToggle}
				actions={[
					<Button
						key="confirm"
						variant="primary"
						onClick={handleSave}
						isLoading={isFetching}
						isDisabled={isFetching}
					>
						Create
					</Button>,
					<Button key="cancel" variant="link" onClick={handleModalToggle}>
						Cancel
					</Button>,
				]}
			>
				<Form onSubmit={() => {}} isHorizontal>
					{Object.entries(createFormStructure).map((item: any[]) => (
						<FormGroup label={item[1]?.display} required>
							<TextInput
								type="text"
								onChange={(value) => handleChange(value, item[0])}
							/>
						</FormGroup>
					))}
				</Form>
			</Modal>
		</>
	);
};
