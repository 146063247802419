import { useState } from "react"

export const useFetch = <T>(apiRequest: (...args: any[]) => Promise<T>, extraParams?: any) => {

    const [isFetching, setFetching] = useState(false);
    const [error, setError] = useState<any>();
    const [data, setData] = useState<T>();

    const callApi = async (...args: any) => {
        setFetching(true);
        try {
            let response = await apiRequest(...args);
            setData(response);
            return response;
        } catch (err) {
            setError(err);
        } finally {
            setFetching(false);
        }
    }
    return { callApi, data, isFetching, error };
}